import { useEffect, useState, useRef } from "react";
import Firebase from "./Firebase";

const FirebaseInstance = new Firebase();

function useFirebaseInit() {
  const [[firebase, authUser], setFirebaseState] = useState([
    FirebaseInstance,
    null,
  ]);

  const authListener = useRef();

  useEffect(
    () => {
      authListener.current = firebase.auth.onAuthStateChanged(authedUser =>
        authedUser
          ? setFirebaseState([firebase, authedUser])
          : setFirebaseState([firebase, null])
      );
      return () => {
        authListener.current();
      };
    },
    [FirebaseInstance]
  );

  const firebaseContextValue = [firebase, authUser];
  return firebaseContextValue;
}

export default useFirebaseInit;
