import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

import FormControlField from "../FormControlField";
import { FirebaseContext } from "../Firebase";
import * as routes from "../../constants/routes";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

const SignUpSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  password: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don't match")
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
});

function SignUpPage(props) {
  const { classes, history } = props;
  const [error, setError] = useState(false);
  const [firebase] = useContext(FirebaseContext);
  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        {error && <p>{error}</p>}
        <Formik
          initialValues={{
            name: "",
            email: "",
            password: "",
            passwordConfirm: "",
          }}
          validationSchema={SignUpSchema}
          onSubmit={({ email, password, name }) => {
            setError(false);
            firebase
              .doCreateUserWithEmailAndPassword(email, password)
              .then(authUser => {
                // Create a user in your Firebase realtime database
                return firebase.user(authUser.user.uid).set({
                  name,
                  email,
                });
              })
              .then(() => {
                history.push(routes.HOME);
              })
              .catch(error => {
                setError(error.message);
              });
          }}
          render={({ errors, touched }) => (
            <Form className={classes.form}>
              <FormControlField
                label="Full Name"
                name="name"
                autoFocus={true}
                autoComplete="name"
                error={errors.name}
                touched={touched.name}
              />
              <FormControlField
                label="Email"
                name="email"
                autoComplete="email"
                error={errors.email}
                touched={touched.email}
              />
              <FormControlField
                label="Password"
                name="password"
                type="password"
                autoComplete="off"
                error={errors.password}
                touched={touched.password}
              />
              <FormControlField
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                autoComplete="off"
                error={errors.confirmPassword}
                touched={touched.confirmPassword}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign up
              </Button>
            </Form>
          )}
        />
        <hr />
        <Button onClick={() => history.push(routes.LOG_IN)}>Log in</Button>
      </Paper>
    </main>
  );
}

SignUpPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withRouter
)(SignUpPage);
