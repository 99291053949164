import { useContext, useEffect, useRef, useState } from "react";
import { __RouterContext } from "react-router";

import * as routes from "../../constants/routes";

import { FirebaseContext } from "./";

function useFirebaseAuthRedirect(
  { condition = authUser => !!authUser, path = routes.LOG_IN } = {
    condition: authUser => !!authUser,
    path: routes.LOG_IN,
  }
) {
  const [firebase, authUser] = useContext(FirebaseContext);
  const [isAuthorized, setIsAuthorized] = useState(condition(authUser));

  const { history } = useContext(__RouterContext);
  const listener = useRef();

  useEffect(
    () => {
      listener.current = firebase.auth.onAuthStateChanged(authedUser => {
        if (!condition(authedUser)) {
          setIsAuthorized(false);
          history.push(path);
        } else {
          setIsAuthorized(true);
        }
      });
      return () => {
        listener.current();
      };
    },
    [firebase]
  );

  return isAuthorized;
}

export default useFirebaseAuthRedirect;
