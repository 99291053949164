import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as Yup from "yup";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import isEqual from "react-fast-compare";
import { FirebaseContext, useFirebaseAuthRedirect } from "../Firebase";

import FormControlField from "../FormControlField";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "95%",
      maxWidth: 620,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  progress: {
    width: "100%",
  },
});

const EditTranscriptionSchema = Yup.object().shape({
  file: Yup.string().required("Required"),
});

const AWord = React.memo(function AWord({ children }) {
  const [state, setState] = useState(children);
  const onChange = e => console.log(e);
  const listenerRef = useRef();
  const elementRef = useRef();
  const charModifiedListener = useCallback(function(event) {
    console.log("event", event);
    setState(event.newValue);
  });
  useEffect(() => {
    elementRef.current.addEventListener(
      "DOMCharacterDataModified",
      charModifiedListener,
      false
    );
    return () => {
      if (typeof listenerRef.current === "function") {
        listenerRef.current();
      }
    };
  }, []);
  return (
    <span ref={elementRef} onChange={onChange}>
      {children}
    </span>
  );
});

const ASegment = React.memo(function ASegment({
  isActive = false,
  resultIndex,
  words,
  onClick,
}) {
  console.log("I should not re-render");
  const handleOnClick = function() {
    onClick(resultIndex);
  };
  const mappedWords = words.map((word, ii) => (
    <AWord key={ii}>{`${word.word} `}</AWord>
  ));
  return (
    <AParagraph
      handleOnClick={handleOnClick}
      isActive={isActive}
      mappedWords={mappedWords}
    />
  );
},
isEqual);

const AParagraph = React.memo(function AParagraph({
  handleOnClick,
  isActive,
  mappedWords,
}) {
  const aRef = useRef();
  useEffect(
    () => {
      if (isActive) {
        aRef.current.focus();
      }
    },
    [isActive]
  );
  return (
    <p
      ref={aRef}
      onClick={handleOnClick}
      spellCheck={false}
      data-gramm_editor="false"
      data-gramm="false"
      data-enable-grammarly="false"
      contentEditable={isActive}
      focus={isActive}
    >
      {mappedWords}
    </p>
  );
});

const WordComponents = React.memo(function WordComponents({
  activeSegment,
  segments,
  handleSegmentOnClick,
}) {
  const render = segments.map((segment, i) => {
    const isActive = activeSegment === i;
    return (
      <ASegment
        isActive={isActive}
        key={i}
        resultIndex={i}
        words={segment}
        onClick={handleSegmentOnClick}
      />
    );
  });
  return render;
});

const EditTranscriptionPage = React.memo(function(props) {
  console.log("props", props);
  const {
    classes,
    history,
    match: {
      params: { id: transcriptId },
    },
  } = props;
  const [feedback, setFeedback] = useState(false);
  const [firebase, authUser] = useContext(FirebaseContext);

  const [title, setTitle] = useState("");
  const [transcript, setTranscript] = useState({});
  const [segments, setSegments] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [activeSegment, setActiveSegment] = useState(null);

  const isAuthorized = useFirebaseAuthRedirect();

  const transcriptListener = useRef();

  const handleSegmentOnClick = useCallback(function(a) {
    setActiveSegment(a);
  }, []);

  useEffect(
    () => {
      if (authUser) {
        transcriptListener.current = firebase
          .transcript(transcriptId)
          .onSnapshot(snap => {
            console.log(snap);
            const parsedData = {
              id: snap.id,
              ...snap.data(),
            };
            console.log(parsedData);
            setTitle(parsedData.title);
            setTranscript(parsedData);
          });
      }

      return () => {
        if (typeof transcriptListener.current === "function") {
          transcriptListener.current();
        }
      };
    },
    [authUser, transcriptId]
  );

  useEffect(
    () => {
      if (authUser && transcript.autoTranscriptionProgress === 'complete') {
        setFeedback('Loading the transcripts words, this may take a minute or 2...');
        firebase
          .transcript(transcriptId)
          .collection("autoTranscriptionResults")
          .get()
          .then(async results => {
            console.log("here", results);
            let tmpSegments = [];
            for (let result in results.docs) {
              await results.docs[result].ref
                .collection("alternatives")
                .get()
                .then((alternatives, ii) => {
                  const firstAlternative = alternatives.docs[0].data();
                  const resultIndex = firstAlternative.resultIndex;
                  tmpSegments[resultIndex] = firstAlternative.words;
                });
            }

            return tmpSegments;
          })
          .then(words => {
            setSegments(words);
            setFeedback(false);
            console.log("words?", words);
          });
      }

      return () => {};
    },
    [authUser, transcriptId, transcript]
  );

  const handleTitleChange = useCallback(function(e) {
    const value = e.target.value;
    setTitle(value);
  }, []);

  const saveTranscript = useCallback(function(e) {}, []);

  console.log("activeSegment", activeSegment);

  return (
    isAuthorized && (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Edit Transcription
          </Typography>
          {feedback && <p>{feedback}</p>}
          <FormControl
            margin="normal"
            fullWidth
            variant="outlined"
            error={false}
          >
            <InputLabel htmlFor="title">Title</InputLabel>
            <OutlinedInput
              name="title"
              notched={true}
              onChange={handleTitleChange}
              value={title}
            />
            <FormHelperText />
          </FormControl>
          <div>
            <WordComponents
              activeSegment={activeSegment}
              segments={segments}
              handleSegmentOnClick={handleSegmentOnClick}
            />
          </div>
          {/* {transcript && transcript.transcription && <div contentEditable="true">{transcript.transcription}</div>} */}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={saveTranscript}
            disabled={isSubmitting}
          >
            Save
          </Button>
        </Paper>
      </main>
    )
  );
});

EditTranscriptionPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withRouter
)(EditTranscriptionPage);
