import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as Yup from "yup";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { FirebaseContext, useFirebaseAuthRedirect } from "../Firebase";

import FormControlField from "../FormControlField";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  progress: {
    width: "100%",
  },
});

const CreateTranscriptionSchema = Yup.object().shape({
  file: Yup.string().required("Required"),
});

function CreateTranscriptionPage(props) {
  const { classes, history } = props;
  const [feedback, setFeedback] = useState(false);
  const [firebase, authUser] = useContext(FirebaseContext);

  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [transcriptId, setTranscriptId] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(null);
  const [uploadProgress, setuploadProgress] = useState(0);

  const isAuthorized = useFirebaseAuthRedirect();

  const handleFileOnChange = function(e) {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
  };

  const handleTitleChange = function(e) {
    console.log("here", e.target.value);
    const value = e.target.value;
    setTitle(value);
  };

  console.log("authUser", authUser);

  const transcriptIdRef = useRef();

  const createTranscript = () => {
    setIsSubmitting(true);
    console.log("HERE 1", title, authUser, transcriptId);
    firebase
      .transcripts()
      .add({
        title,
        userId: authUser.uid,
        createdAt: firebase.Timestamp.now(),
        updatedAt: firebase.Timestamp.now(),
      })
      .then(docRef => {
        setFeedback("Document successfully written!");
        setTranscriptId(docRef.id);
        transcriptIdRef.current = docRef.id;
        handleUpload();
      })
      .catch(error => {
        setIsSubmitting(false);
        console.error(error);
        setFeedback(`Error writing document: ${error.message}`);
      });
  };

  const handleUpload = () => {
    console.log(file);
    const storageRef = firebase
      .transcriptUpload(transcriptIdRef.current)
      .child(file.name)
      .put(file, { customMetadata: { transcriptId: transcriptIdRef.current } });
    const transcriptRef = firebase.transcript(transcriptIdRef.current);
    // Listen for state changes, errors, and completion of the upload.
    storageRef.on(
      "state_changed", // or 'state_changed'
      function(snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setuploadProgress(progress);
        switch (snapshot.state) {
          case "paused": // or 'paused'
            setFeedback("Upload is paused");
            break;
          case "running": // or 'running'
            setFeedback("Uploading");
            break;
          default:
        }
      },
      function(error) {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        setuploadProgress(0);
        setIsSubmitting(false);
        console.error(error);
        setFeedback(`Upload Error: ${error.message}`);
      },
      function() {
        // Upload completed successfully, now we can get the download URL

        storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          transcriptRef
            .set(
              {
                mediaFileName: file.name,
                mediaFileURL: downloadURL,
                updatedAt: firebase.Timestamp.now(),
              },
              { merge: true }
            )
            .then(function() {
              setFeedback(`File available at ${downloadURL}`);
              setuploadProgress(0);
              setIsSubmitting(false);
              firebase
                .transcript(transcriptIdRef.current)
                .onSnapshot(function(doc) {
                  const data = doc.data();
                  console.log("Current data: ", data);
                  if (data.transcription) {
                    setFeedback(data.transcription);
                  }
                });
            })
            .catch(function(error) {
              setuploadProgress(0);
              setIsSubmitting(false);
              setFeedback(`Error writing document: ${error.message}`);
              console.error(error);
            });
        });
      }
    );
  };

  return (
    isAuthorized && (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Create Transcription
          </Typography>
          {feedback && <p>{feedback}</p>}
          {isSubmitting && (
            <LinearProgress
              className={classes.progress}
              variant="determinate"
              value={uploadProgress}
            />
          )}
          <FormControl
            margin="normal"
            fullWidth
            variant="outlined"
            error={false}
          >
            <InputLabel htmlFor="title">Title</InputLabel>
            <OutlinedInput
              name="title"
              notched={true}
              onChange={handleTitleChange}
              value={title}
            />
            <FormHelperText />
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
            variant="outlined"
            error={false}
          >
            <OutlinedInput
              name="file"
              type="file"
              notched={true}
              onChange={handleFileOnChange}
            />
            <FormHelperText />
          </FormControl>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={createTranscript}
            disabled={isSubmitting}
          >
            Upload
          </Button>
        </Paper>
      </main>
    )
  );
}

CreateTranscriptionPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withRouter
)(CreateTranscriptionPage);
