import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import MaterialTable, { MTableCell } from "material-table";

import { useFirebaseAuthRedirect } from "../Firebase";
import { FirebaseContext } from "../Firebase";
import AlertDialog from "../AlertDialog";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      maxWidth: 1280,
      width: "95%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
});

function HomePage(props) {
  const { classes } = props;
  const isAuthorized = useFirebaseAuthRedirect();
  const [firebase, authUser] = useContext(FirebaseContext);

  if (!authUser || !isAuthorized) {
    return null;
  }

  const [data, setData] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const handleCancel = () => setAlertOpen(false);
  const handleConfirm = rows => {
    firebase.transcript();
    setAlertOpen(false);
  };

  const listener = useRef();

  useEffect(
    () => {
      if (authUser) {
        listener.current = firebase
          .usersTranscripts(authUser.uid)
          .onSnapshot(snap => {
            // console.log(snap.docs);
            const parsedData = snap.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }));
            setData(parsedData);
          });
      }

      return () => {
        listener.current();
      };
    },
    [authUser]
  );

  const columns = [
    { title: "Title", field: "title", sorting: true },
    { title: "Metadata parsed", field: "fileParsed", sorting: true },
    { title: "Flac file created", field: "flacFileCreated", sorting: true },
    {
      title: "Auto transcription progress",
      field: "autoTranscriptionProgress",
      sorting: true,
    },
  ];

  const options = {
    filtering: true,
    selection: true,
    pageSize: 10,
    pageSizeOptions: [10, 15, 20],
  };

  const valueConverter = value => {
    if (typeof value !== "string" && typeof value !== "number") {
      return JSON.stringify(value);
    }
    return value;
  };

  return (
    isAuthorized && (
      <main className={classes.main}>
        {alertOpen && (
          <AlertDialog
            open={alertOpen}
            handleCancel={handleCancel}
            handleConfirm={handleConfirm}
          />
        )}
        <MaterialTable
          title={"Transcripts"}
          options={options}
          data={data}
          columns={columns}
          actions={[
            {
              icon: "delete",
              tooltip: "Delete",
              onClick: (event, rows) => {
                console.log("selected rows", rows);
                setAlertOpen(true);
              },
            },
          ]}
          components={{
            Cell: ({ value, ...props }) => (
              <MTableCell value={valueConverter(value)} {...props} />
            ),
          }}
        />
      </main>
    )
  );
}

HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withRouter
)(HomePage);
