import React from "react";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Field, ErrorMessage } from "formik";

function FormControlField({
  label,
  name,
  type = "text",
  autoComplete = "",
  autoFocus = false,
  error,
  touched,
}) {
  return (
    <FormControl
      margin="normal"
      fullWidth
      variant="outlined"
      error={error && touched}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Field
        name={name}
        render={({ field }) => (
          <OutlinedInput
            autoComplete={autoComplete !== "" ? autoComplete : name}
            autoFocus={autoFocus}
            type={type}
            notched={true}
            {...field}
          />
        )}
      />
      <ErrorMessage component={FormHelperText} name={name} />
    </FormControl>
  );
}

export default FormControlField;
