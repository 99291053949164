import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

import FormControlField from "../FormControlField";
import { FirebaseContext } from "../Firebase";
import * as routes from "../../constants/routes";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

const PasswordResetSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
});

function PasswordResetPage(props) {
  const { classes, history } = props;
  const [error, setError] = useState(false);
  const [firebase] = useContext(FirebaseContext);

  const [formSubmitted, setFormSubmitted] = useState(false);

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Password Reset
        </Typography>
        {error && <p>{error}</p>}
        {formSubmitted && (
          <p>Please check your inbox for a link to reset your password</p>
        )}
        {!formSubmitted && (
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={PasswordResetSchema}
            onSubmit={values => {
              setError(false);
              firebase
                .doPasswordReset(values.email)
                .then(() => {
                  setFormSubmitted(true);
                })
                .catch(error => {
                  setError(error.message);
                });
            }}
            render={({ errors, touched }) => (
              <Form className={classes.form}>
                <FormControlField
                  label="Email"
                  name="email"
                  autoComplete="email"
                  error={errors.email}
                  touched={touched.email}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Reset Password
                </Button>
              </Form>
            )}
          />
        )}
        <hr />
        <Button onClick={() => history.push(routes.LOG_IN)}>Log in</Button>
      </Paper>
    </main>
  );
}

PasswordResetPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withRouter
)(PasswordResetPage);
