import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.firestore();
    this.db.settings({ timestampsInSnapshots: true });
    this.storage = app.storage();
    this.Timestamp = app.firestore.Timestamp;
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** User API ***

  users = () => this.db.collection("users");

  user = uid => this.users().doc(uid);
  // .get()
  // .then(function(doc) {
  //   if (doc.exists) {
  //     console.log("Document data:", doc.data());
  //     return doc.data();
  //   } else {
  //     // doc.data() will be undefined in this case
  //     console.log("No such document!");
  //     return new Error("No such document!");
  //   }
  // })
  // .catch(function(error) {
  //   console.log("Error getting document:", error);
  //   return error;
  // });

  // *** Transcripts API ***

  transcripts = () => this.db.collection("transcripts");
  transcript = transcriptId => this.transcripts().doc(transcriptId);

  usersTranscripts = uid => this.transcripts().where("userId", "==", uid);

  // *** Uploads API ***

  uploads = () => this.storage.ref().child("uploads");
  transcriptUpload = transcriptId => this.uploads().child(transcriptId);
}

export default Firebase;
