import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";

import * as routes from "../../constants/routes";

import { FirebaseContext } from "../Firebase";

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

function Navigation(props) {
  const { classes } = props;
  const [firebase, authUser] = useContext(FirebaseContext);
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            className={"classes.menuButton"}
            color="inherit"
            aria-label="Menu"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            ATranscriber
          </Typography>
          {!authUser && (
            <>
              <Button component={Link} to={routes.SIGN_UP} color="inherit">
                Sign up
              </Button>
              <Button component={Link} to={routes.LOG_IN} color="inherit">
                Log in
              </Button>
            </>
          )}
          {authUser && (
            <>
              <Button
                component={Link}
                to={routes.CREATE_TRANSCRIPTION}
                color="inherit"
              >
                Create Transcription
              </Button>
              <Button color="inherit" onClick={firebase.doSignOut}>
                Log out
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigation);
