import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import pink from "@material-ui/core/colors/pink";

import * as routes from "../../constants/routes";

import { FirebaseContext, useFirebaseInit } from "../Firebase";
import Navigation from "../Navigation";
import LogInPage from "../LogInPage";
import SignUpPage from "../SignUpPage";
import PasswordResetPage from "../PasswordResetPage";
import HomePage from "../HomePage";
import CreateTranscriptionPage from "../CreateTranscriptionPage";
import EditTranscriptionPage from '../EditTranscriptionPage';

const theme = createMuiTheme({
  palette: {
    primary: blue, // Purple and green play nicely together.
    secondary: pink, // This is just green.A700 as hex.
  },
});

const App = () => {
  const firebase = useFirebaseInit();

  return (
    <>
      <FirebaseContext.Provider value={firebase}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <div>
              <Navigation />
              <Route
                path={routes.PASSWORD_RESET}
                component={PasswordResetPage}
              />
              <Route path={routes.LOG_IN} component={LogInPage} />
              <Route path={routes.SIGN_UP} component={SignUpPage} />
              <Route path={routes.HOME} component={HomePage} />
              <Route
                path={routes.CREATE_TRANSCRIPTION}
                component={CreateTranscriptionPage}
              />
              <Route
                path={routes.EDIT_TRANSCRIPTION}
                component={EditTranscriptionPage}
              />
            </div>
          </Router>
        </MuiThemeProvider>
      </FirebaseContext.Provider>
    </>
  );
};

export default App;
