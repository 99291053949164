import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

import FormControlField from "../FormControlField";
import { FirebaseContext, useFirebaseAuthRedirect } from "../Firebase";
import * as routes from "../../constants/routes";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

const LogInSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  password: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
});

function LogInPage(props) {
  const { classes, history } = props;
  const [error, setError] = useState(false);
  const [firebase] = useContext(FirebaseContext);

  const isNotAuthorized = useFirebaseAuthRedirect({
    condition: authUser => !authUser,
    path: routes.HOME,
  });

  /**
   * isAuthed here is weird because the condition is actualy
   */
  return (
    isNotAuthorized && (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Log in
          </Typography>
          {error && <p>{error}</p>}
          <Formik
            initialValues={{
              username: "",
              email: "",
              password: "",
              passwordConfirm: "",
            }}
            validationSchema={LogInSchema}
            onSubmit={values => {
              setError(false);
              firebase
                .doSignInWithEmailAndPassword(values.email, values.password)
                .then(authUser => {
                  history.push(routes.HOME);
                })
                .catch(error => {
                  setError(error.message);
                });
            }}
            render={({ errors, touched }) => (
              <Form className={classes.form}>
                <FormControlField
                  label="Email"
                  name="email"
                  autoComplete="email"
                  error={errors.email}
                  touched={touched.email}
                />
                <FormControlField
                  label="Password"
                  name="password"
                  type="password"
                  autoComplete="off"
                  error={errors.password}
                  touched={touched.password}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Log in
                </Button>
              </Form>
            )}
          />
          <hr />
          <Button onClick={() => history.push(routes.PASSWORD_RESET)}>
            Forgot your password?
          </Button>
        </Paper>
      </main>
    )
  );
}

LogInPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withRouter
)(LogInPage);
